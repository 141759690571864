import { render, staticRenderFns } from "./SupplierView.vue?vue&type=template&id=154299dd&scoped=true&"
import script from "./SupplierView.vue?vue&type=script&lang=js&"
export * from "./SupplierView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154299dd",
  null
  
)

export default component.exports