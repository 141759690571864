<template>
  <v-container fluid>
    <v-row no-gutters>
      <!-- Main Card -->
      <v-col cols="12" v-if="isLoaded" class="mt-6">
        <v-card class="mx-2">
          <v-row no-gutters>
            <v-col cols="12" class="mt-2">
              <v-row justify="space-between" no-gutters>
                <div class="mx-2">
                  <h1>{{ item.name }}</h1>
                </div>
                <div class="mx-2">
                  <v-chip label class="mx-1">{{ item.type }}</v-chip>
                  <v-btn icon @click="setShowMainEditDialog(true)">
                    <v-icon>fas fa-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="setShowDeleteDialog(true)">
                    <v-icon>far fa-trash</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-col>

            <!-- General info -->
            <v-col cols="12">
              <v-row no-gutters class="ma-2">
                <!-- FM Referent -->
                <v-col cols="12">
                  <span>{{ $t("suppliers.labels.fm_referents") }} :</span>
                  <FMReferent
                    v-for="fm_referent of item.fm_referents"
                    :key="fm_referent.id"
                    :item="fm_referent"
                  />
                </v-col>

                <!-- Parent -->
                <v-col v-if="item.parent" cols="12">
                  <span>{{ $t("suppliers.labels.parent") }}</span>
                  <router-link
                    :to="{ name: 'Account', param: { id: item.parent.id } }"
                  >
                    {{ item.parent.name }}
                  </router-link>
                </v-col>

                <!-- Main Address -->
                <v-col cols="12">
                  <span class="heading">
                    {{ $t("suppliers.labels.address") }} :
                  </span>
                  <AddressInline :address="item.address" />
                </v-col>

                <!-- Category -->
                <v-col cols="12">
                  <span class="heading">
                    {{ $t("suppliers.labels.category") }} :
                  </span>
                  <span>
                    <v-chip label>{{ item.category }}</v-chip>
                  </span>
                </v-col>

                <!-- Sub categories -->
                <v-col cols="12">
                  <span class="heading">
                    {{ $t("suppliers.labels.subcategories") }} :
                  </span>
                  <span v-if="item.subcategories.length > 0">
                    <v-chip
                      v-for="subcategory of item.subcategories"
                      :key="subcategory"
                      label
                    >
                      {{ subcategory }}
                    </v-chip>
                  </span>
                  <span v-else>{{ $t("common.misc.NA") }}</span>
                </v-col>

                <!-- Website -->
                <v-col cols="12">
                  <span class="heading">
                    {{ $t("suppliers.labels.website") }} :
                  </span>
                  <span>{{ item.details.website }}</span>
                </v-col>

                <!-- Nb employees -->
                <v-col cols="12">
                  <span class="heading">
                    {{ $t("suppliers.labels.nb_employees") }} :
                  </span>
                  <span>{{ item.details.nb_employees }}</span>
                </v-col>

                <!-- Registration date -->
                <v-col cols="12">
                  <span class="heading">
                    {{ $t("suppliers.labels.createdAt") }}
                  </span>
                  <span>{{ formatDate(item.createdAt) }}</span>
                </v-col>

                <!-- Can edit forms -->
                <v-col cols="12">
                  <span class="heading">
                    {{ $t("suppliers.labels.canEditForms") }} :
                  </span>
                  <span v-if="item.canEditForms">
                    {{ $t("common.misc.yes") }}
                  </span>
                  <span v-else>{{ $t("common.misc.no") }}</span>
                </v-col>

                <!-- Compliance grade -->
                <v-col cols="12">
                  <span class="heading">
                    {{ $t("suppliers.labels.complianceGrade") }} :
                  </span>
                  <span>
                    <ProgressPercentage :value="item.complianceGrade * 100" />
                  </span>
                </v-col>

                <!-- Form part -->
                <v-col cols="12" class="mt-2">
                  <v-divider />
                  <div class="text-h4">
                    {{ $t("suppliers.tabs.forms.title") }}
                  </div>
                  <v-row no-gutters>
                    <!-- Supplier form Submission -->
                    <v-col cols="12">
                      <span class="heading">
                        {{
                          $t(
                            "suppliers.tabs.forms.labels.supplierForm.last_submission"
                          )
                        }}
                        :
                      </span>
                      <span v-if="item.supplierFormSubmission">
                        {{
                          formatDatetime(
                            item.supplierFormSubmission.last_form_submission
                          )
                        }}, {{ item.supplierFormSubmission.state }}
                      </span>
                      <span v-else>{{ $t("common.misc.never") }}</span>
                    </v-col>

                    <!-- Supplier form link -->
                    <v-col cols="12">
                      <a target="_blank" :href="linkSupplierForm">
                        {{ $t("suppliers.labels.linkSupplierForm") }}
                      </a>
                      <ButtonIconCopy :value="linkSupplierForm" />
                    </v-col>

                    <!-- Material forms link -->
                    <v-col cols="12">
                      <a target="_blank" :href="linkMaterialForms">
                        {{ $t("suppliers.labels.linkMaterialForms") }}
                      </a>
                      <ButtonIconCopy :value="linkMaterialForms" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Tabs card -->
      <v-col cols="12" class="mt-6">
        <v-card class="mx-2">
          <v-tabs class="mx-4" v-model="tab" grow @change="switchTab">
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-1"
              :exact="true"
            >
              {{ $t("suppliers.tabs.contacts.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-2"
              :exact="true"
            >
              {{ $t("suppliers.tabs.factory-performances.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-3"
              :exact="true"
            >
              {{ $t("suppliers.tabs.products.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-4"
              :exact="true"
            >
              {{ $t("suppliers.tabs.components.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-5"
              :exact="true"
            >
              {{ $t("suppliers.tabs.material-forms.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-6"
              :exact="true"
            >
              {{ $t("suppliers.tabs.certifications.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-7"
              :exact="true"
            >
              {{ $t("suppliers.tabs.files.title") }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="ma-1">
            <!-- Contacts -->
            <v-tab-item value="tab-1">
              <SupplierContactsTab
                v-if="item"
                :supplier="item"
                @refresh="getData"
              />
              <Skeleton v-else type="table" :occurrence="1" />
            </v-tab-item>

            <!-- Factory performances -->
            <v-tab-item value="tab-2">
              <SupplierFactoryPerformanceTab
                v-if="item"
                :supplier="item"
                @refresh="getData"
              />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>

            <!-- Products -->
            <v-tab-item value="tab-3">
              <CommonStudiesTab v-if="item" :supplier="item" />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>

            <!-- Components -->
            <v-tab-item value="tab-4">
              <ComponentsTab v-if="item" :supplier="item" />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>

            <!-- Material forms -->
            <v-tab-item value="tab-5">
              <MaterialFormsTab v-if="item" :supplier="item" />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>

            <!-- Certifications -->
            <v-tab-item value="tab-6">
              <SupplierCertificationsTab v-if="item" :supplier="item" />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>

            <!-- Files -->
            <v-tab-item value="tab-7">
              <SupplierFilesTab v-if="item" :supplier="item" />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <!-- Supplier main dialog -->
    <SupplierMainDialog
      v-if="showDialog.main"
      :value="showDialog.main"
      :item="item"
      @close="(needRefresh) => setShowMainEditDialog(false, needRefresh)"
    />

    <!-- Supplier delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('suppliers.deleteDialog.title')"
      @submit="deleteItem"
      @close="setShowDeleteDialog(false)"
    />
  </v-container>
</template>

<script>
import formatter from "@/mixins/formatter";
import tabsUtils from "@/mixins/tabs-utils";
export default {
  name: "Supplier",

  components: {
    MaterialFormsTab: () =>
      import("@/components/Common/MaterialForms/MaterialFormsTab"),
    ProgressPercentage: () => import("@/components/Common/ProgressPercentage"),
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    SupplierMainDialog: () =>
      import("@/components/Suppliers/SupplierMainDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
    FMReferent: () => import("@/components/Common/Cards/FMReferent"),
    AddressInline: () => import("@/components/Common/Cards/AddressInline"),
    ButtonIconCopy: () => import("@/components/Common/Buttons/ButtonIconCopy"),

    // Tabs
    CommonStudiesTab: () =>
      import("@/components/Common/Studies/CommonStudiesTab"),
    SupplierContactsTab: () =>
      import("@/components/Suppliers/Contacts/SupplierContactsTab"),
    SupplierFilesTab: () =>
      import("@/components/Suppliers/Files/SupplierFilesTab"),
    ComponentsTab: () => import("@/components/Common/Components/ComponentsTab"),
    SupplierFactoryPerformanceTab: () =>
      import(
        "@/components/Suppliers/FactoryPerformance/SupplierFactoryPerformanceTab"
      ),
    SupplierCertificationsTab: () =>
      import("@/components/Suppliers/Certifications/SupplierCertificationsTab"),
  },

  mixins: [formatter, tabsUtils],

  data() {
    return {
      showDialog: {
        main: false,
        delete: false,
        unlink: false,
      },

      isLoaded: false,
      isLoading: {
        btnSave: false,
      },

      item: null,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoaded = false;
      this.isLoading.btnSave = false;

      this.$http
        .get(`/suppliers/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.item = res.data;
          this.$store.commit("pageHead/setTitle", `${this.item.name}`);
        })
        .catch((err) => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },

    deleteItem() {
      this.$http
        .delete(`/suppliers/${this.item.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("suppliers.deleteDialog.success")
          );
          this.setShowDeleteDialog(false);
          this.$router.push({ name: "Suppliers" });
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
    },

    setShowMainEditDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.main = val;
    },

    setShowDeleteDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.delete = val;
    },

    setShowProductAddDialog(val, reloadData = false) {
      if (reloadData) this.getProducts();
      this.showDialog.addProduct = val;
    },

    setShowProductDeleteDialog(val, product = null, reloadData = false) {
      this.products.currentProduct = product;

      if (reloadData) this.getProducts();
      this.showDialog.deleteProduct = val;
    },
  },

  computed: {
    linkSupplierForm() {
      return `${process.env.VUE_APP_IMPACT_FORMS_URL}/suppliers/${this.item.ref}`;
    },
    linkMaterialForms() {
      return `${process.env.VUE_APP_IMPACT_FORMS_URL}/suppliers/${this.item.ref}/materials`;
    },
  },
};
</script>

<style scoped></style>
