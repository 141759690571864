<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>{{ $t("suppliers.title") }}</h1>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="mt-6 text-right">
        <v-btn color="primary" @click="setShowAddDialog(true)">
          {{ $t("suppliers.buttons.add") }}
        </v-btn>
      </v-col>

      <v-col cols="12" class="mt-6">
        <div>
          <v-text-field
            v-model="search"
            append-icon="fas fa-search"
            :label="$t('common.inputs.search')"
            clearable
            v-on:click:clear="clearInput"
            @keyup.enter.native="searchTrigger"
            outlined
          ></v-text-field>
        </div>
      </v-col>

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          style="min-height: 600px"
          sort-by="name"
          :sort-desc="false"
          :footer-props="{ 'items-per-page-options': itemsPerPage }"
          class="elevation-1"
        >
          <!-- Name -->
          <template v-slot:item.name="{ item }">
            <router-link :to="{ name: 'Supplier', params: { id: item.id } }">
              {{ item.name }}
            </router-link>
          </template>

          <!-- Type -->
          <template v-slot:item.type="{ item }">
            <v-chip label>{{ item.type }}</v-chip>
          </template>

          <!-- Category -->
          <template v-slot:item.category="{ item }">
            <v-chip label>{{ item.category }}</v-chip>
          </template>

          <!-- Country -->
          <template v-slot:item.country="{ item }">
            {{ item.country }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main Supplier dialog -->
    <SupplierMainDialog
      v-if="showDialog.add"
      :value="showDialog.add"
      :item="null"
      @close="(needRefresh) => setShowAddDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import vuetifyDatatable from "@/mixins/vuetifyDataTable";
import formatter from "@/mixins/formatter";

export default {
  name: "SuppliersView",

  components: {
    SupplierMainDialog: () =>
      import("@/components/Suppliers/SupplierMainDialog"),
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      headers: [
        {
          text: this.$t("suppliers.datatable.headers.name"),
          value: "name",
          sortable: false,
          width: "30%",
        },
        {
          text: this.$t("suppliers.datatable.headers.type"),
          value: "type",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("suppliers.datatable.headers.category"),
          value: "category",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("suppliers.datatable.headers.country"),
          value: "country",
          sortable: false,
          width: "10%",
        },
      ],
      URL_API: {
        GET_ALL: "/suppliers",
      },

      showDialog: {
        add: false,
      },
      isLoadingExportBtn: false,
      savePaginationURL: true,
    };
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t("suppliers.pageTitle"));
  },

  methods: {
    setShowAddDialog(val, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.showDialog.add = val;
    },

    goToOne(obj) {
      this.$router.push({
        name: "Supplier",
        params: { id: obj.id },
      });
    },
  },
};
</script>

<style scoped></style>
